import { graphql } from "gatsby";
import { map } from "lodash-es";
import React from "react";
import { Layout, ScrollToTopBtn } from "../components";
import Footer from "../components/Footer";
import FormField from "../components/FormField";
import Header from "../components/Header";
import { Props } from "../models/props";
import { htmlToReact } from "../utils";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String, $strapiId: Int) {
    sitePage(path: { eq: $url }) {
      id
    }
    contact(strapiId: { eq: $strapiId }) {
      id
      image_background {
        id
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

const Contact = ({ data, pageContext, ...props }: Props): JSX.Element => {
  const backgroundImage = data?.contact?.image_background?.localFile;
  const title = pageContext.frontmatter?.title;
  const subtitle = pageContext.frontmatter?.subtitle;
  const formId = pageContext.frontmatter?.form_id;
  const formAction = pageContext.frontmatter?.form_action;
  const form_fields = pageContext.frontmatter?.form_fields;
  const submitLabel = pageContext.frontmatter?.submit_label;
  const html = pageContext.html;
  return (
    <Layout {...props} site={pageContext.site} page={pageContext}>
      <Header
        site={pageContext.site}
        page={pageContext}
        image={backgroundImage}
      />
      <div id="content" className="site-content">
        <main id="main" className="site-main inner">
          <article className="post page post-full">
            <header className="post-header">
              <h1 className="post-title">{title}</h1>
            </header>
            {subtitle && (
              <div className="post-subtitle">{htmlToReact(subtitle)}</div>
            )}
            <div className="post-content">
              {htmlToReact(html)}
              <form
                name={formId}
                id={formId}
                {...(formAction
                  ? {
                      action: formAction,
                    }
                  : null)}
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <div className="screen-reader-text">
                  <label>
                    Don`&apos;`t fill this out if you`&apos;`re human:{" "}
                    <input name="bot-field" />
                  </label>
                </div>
                <input type="hidden" name="form-name" value={formId} />
                {map(form_fields, (field, field_idx) => (
                  <FormField key={field_idx} {...props} field={field} />
                ))}
                <div className="form-submit">
                  <button type="submit" className="button">
                    {submitLabel}
                  </button>
                </div>
              </form>
            </div>
          </article>
        </main>
        <ScrollToTopBtn />
        <Footer site={pageContext.site} />
      </div>
    </Layout>
  );
};

export default Contact;
